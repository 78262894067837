import { useHistory, useRef } from "react-router-dom";
import Pricing from "./Pricing";
import { useEffect, useState } from "react";
import Features from "./Features";
import { getJWT, removeJWT } from "../utils";

function App({ focus }) {
  const history = useHistory();
  const scrollToPricing = () => {
    document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFeatures = () => {
    document.getElementById("features").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (focus === "features") {
      scrollToFeatures();
    } else if (focus === "pricing") {
      scrollToPricing();
    }
  }, [focus]);

  return (
    <div className="overflow-y-scroll md:h-screen md:snap-y md:snap-mandatory">
      <div className="display-none">
        <a href="https://chromewebstore.google.com/detail/translate-netflix-subtitl/cppopffhjdgeijpkpaoebneockpeehdo?utm_source=website">
          Translate Netflix Subtitles
        </a>
      </div>
      <section className="md:h-screen bg-customBlack snap-start">
        {focus === "features" && (
          <div class="flex  z-50  flex-col absolute top-[5px] right-[90px] items-end w-[269px]">
            <svg
              height={95}
              viewBox="0 0 14.73 94.25"
              className="mr-[20px] relative animate-pinExtensionMoving z-[9999]"
            >
              <defs>
                <style>{`.cls-1{fill:#fff;}`}</style>
              </defs>
              <path
                className="cls-1"
                d="M12,3.21a1,1,0,0,0-1.42,0L4.18,9.58A1,1,0,0,0,5.59,11l5.66-5.66L16.91,11a1,1,0,1,0,1.41-1.41Zm.29,94V3.92h-2V97.17Z"
                transform="translate(-3.88 -2.92)"
              />
            </svg>

            <div class="flex gap-[8px] z-[0]">
              <p class="whitespace-nowrap text-white bg-customBlack pl-1 pr-1 rounded">
                Pin Dictionarying before you go
              </p>

              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 10H17V6C17 5.46957 16.7893 4.96086 16.4142 4.58579C16.0391 4.21071 15.5304 4 15 4H11V2.5C11 1.83696 10.7366 1.20107 10.2678 0.732233C9.79893 0.263392 9.16304 0 8.5 0C7.83696 0 7.20107 0.263392 6.73223 0.732233C6.26339 1.20107 6 1.83696 6 2.5V4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6V9.8H1.5C3 9.8 4.2 11 4.2 12.5C4.2 14 3 15.2 1.5 15.2H0V19C0 19.5304 0.210714 20.0391 0.585786 20.4142C0.960859 20.7893 1.46957 21 2 21H5.8V19.5C5.8 18 7 16.8 8.5 16.8C10 16.8 11.2 18 11.2 19.5V21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V15H18.5C19.163 15 19.7989 14.7366 20.2678 14.2678C20.7366 13.7989 21 13.163 21 12.5C21 11.837 20.7366 11.2011 20.2678 10.7322C19.7989 10.2634 19.163 10 18.5 10Z"
                  fill="#ffffff"
                />
              </svg>
            </div>
            <p class="text-white text-right mt-[6px] z-[0] bg-customBlack  rounded pl-1 pr-1">
              It gives you access to your Account
            </p>
          </div>
        )}
        <div className="relative md:h-screen max-w-[1200px] mx-auto">
          <div className="absolute left-0 right-0 z-20 flex flex-row justify-center px-8 text-white sm:justify-between top-6">
            <a href="/">
              <img
                src="/static/logo/dictionarying.svg"
                alt="dictionarying"
                width={150}
                className="cursor-pointer"
              />
            </a>

            <div className="flex-row items-center justify-center hidden gap-8 text-white sm:flex">
              <a
                href="#features"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToFeatures();
                }}
                className="hover:underline hover:text-yellow-300"
              >
                Features
              </a>
              <a
                href="#pricing"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToPricing();
                }}
                className="hover:underline hover:text-yellow-300"
              >
                Pricing
              </a>
              <a
                href="/account"
                className="hover:underline hover:text-yellow-300"
              >
                Account
              </a>
              {getJWT() && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    removeJWT();
                    window.dispatchEvent(
                      new CustomEvent("LOGOUT", { detail: {} })
                    );
                    window.location.reload();
                  }}
                  href="#log-out"
                  className="hover:underline hover:text-yellow-300"
                >
                  Log Out
                </a>
              )}
            </div>
          </div>

          <div class="z-2 relative md:h-[100%] flex flex-col md:flex-row px-8">
            <div className="h-[55%] md:h-[100%] md:w-[40%] sm:flex justify-center ">
              <div class="h-full pt-20 md:pt-0 md:h-auto flex justify-center items-center relative">
                {/* <video
                  className="w-full h-auto boxShadow"
                  playsInline="1"
                  autoPlay="1"
                  loop="1"
                >
                  <source src="/static/landing_video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
                <img src="/static/screen.png" alt="Screen" className="" />
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 144 144"
                  width="110"
                  height="110"
                  className="playSvg"
                  onClick={(e) => {
                    e.preventDefault();
                    window
                      .open(
                        "https://www.youtube.com/watch?v=wS2FzRff0U8",
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  <style type="text/css">{`
	.st0z{fill:#ff0000;}
	.st1z{fill:#FFFFFF;}
  .playSvg{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -32px;
    opacity: 0;
    transition: all 150ms ease 0s;
    width: 65px;
     opacity: 1;
     cursor: pointer;
    }
        @media (max-width: 700px) {
         .playSvg{
             margin-top: -22px;
         }
        }

`}</style>
                  <path
                    d="M129.6,101.7c-1.2,6.3-6.3,11.1-12.6,12c-9.9,1.5-26.4,3.3-45,3.3c-18.3,0-34.8-1.8-45-3.3 c-6.3-0.9-11.4-5.7-12.6-12C13.2,94.8,12,84.6,12,72s1.2-22.8,2.4-29.7c1.2-6.3,6.3-11.1,12.6-12c9.9-1.5,26.4-3.3,45-3.3 s34.8,1.8,45,3.3c6.3,0.9,11.4,5.7,12.6,12c1.2,6.9,2.7,17.1,2.7,29.7C132,84.6,130.8,94.8,129.6,101.7z"
                    className="jsx-c79b1519f2bfba94 st0z"
                  ></path>
                  <path
                    d="M60,93V51l36,21L60,93z"
                    className="jsx-c79b1519f2bfba94 st1z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="md:w-[7%]"></div>
            <div className="md:h-[100%] md:w-[50%] flex flex-col text-white justify-center p-5">
              <h1 className="mb-3 text-4xl text-center">
                Interactive Subtitles Translation
              </h1>
              <div>
                Enhance your language skills while watching videos on Netflix,
                YouTube, Coursera, Udemy, Amazon Prime Video or local videos.
                Simply hover over any word in the subtitles for an instant
                translation!
              </div>
              <div className="flex flex-col items-center gap-10 mt-10">
                <div
                  class="cursor-pointer track-btn relative mx-auto md:mx-0 flex justify-center w-72 md:justify-start items-center z-0 h-14 rounded-xl px-6 text-neutral-50 animate-scale opacity-100 transition duration-500 bg-secondary after:bg-secondary md:bg-primary md:after:bg-primary"
                  onClick={() => {
                    const url =
                      "https://chromewebstore.google.com/detail/translate-netflix-subtitl/cppopffhjdgeijpkpaoebneockpeehdo?utm_source=website";
                    window.open(url, "_blank").focus();
                  }}
                  target="_blank"
                >
                  <img
                    class="w-8 h-8 mr-3"
                    alt="Google Chrome Store"
                    src="/static/chrome-store.svg"
                  />{" "}
                  Install Extension for Free
                </div>
                <div class="flex flex-row items-center gap-2">
                  <div class="hidden sm:flex">
                    <img
                      class="avatar"
                      src="/static/avatars/1.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/2.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/3.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/4.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <div class="flex justify-center items-center font-medium avatar text-xs text-white bg-primary">
                      10K+
                    </div>
                  </div>
                  <div class="flex flex-col space-y-1 text-white md:text-black">
                    <div class="flex sm:justify-start justify-center items-center space-x-2">
                      <div class="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#facc14"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div className="text-white">
                        <strong>3.9</strong> / 5
                      </div>
                    </div>
                    <div className="text-white">
                      Trusted by <strong>10,518+</strong> users
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <h2>Dictionarying Features</h2> */}

      <Features isGetStarted={focus === "features"} />
      <Pricing />
    </div>
  );
}

export default App;
